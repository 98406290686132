import types from '../types';

export const initialState = 0;

export default function clientIdReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CLIENT_ID:
      const clientId = action.payload;
      if(action.payload !== null) {
        return clientId;
      } else return state;
    default:
      return state;
  }
};
