import axios from 'axios';
import baseUrl from "../redux/baseUrl";
import {PublicClientApplication} from "@azure/msal-browser";

async function getAxios() {
    const url = baseUrl;
    const msalConfig = {
        auth: {
            clientId: "a483428a-3497-4176-bcaa-ae0eb15eb09e",
            authority: "https://login.microsoftonline.com/ed752a3a-3d5f-4571-a6bb-fc7a2de4b35e"
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    };
    const msalContext = new PublicClientApplication(msalConfig);
    const accounts = msalContext.getAllAccounts();
    let token = '';
    let axiosInstance;
    if (accounts.length > 0) {
        await msalContext.acquireTokenSilent({
            scopes: ["a483428a-3497-4176-bcaa-ae0eb15eb09e/.default"],
            account: accounts[0]
        })
            .then(response => {
                token = response.accessToken;
                axiosInstance = axios.create({
                    baseURL: url,
                    headers: {Authorization: `Bearer ${token}`}
                });
            })
    }

    return await axiosInstance;
}

export default getAxios;
