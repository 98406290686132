import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";
import {
    MDBAlert,
    MDBBtn,
    MDBInputGroup,
    MDBInputGroupElement,
    MDBInputGroupText,
    MDBNavbar,
    MDBSpinner
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";
import {bindActionCreators, compose} from "redux";
import GetPBMAction from "../../redux/actions/GetPBMAction";
import {connect} from "react-redux";
import axios from "../../util/axios";



class ImportPBMView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            importing: false,
            showError: false,
            showSuccess: false
        }
        this.onChange = this.onChange.bind(this);
        this.formRef = React.createRef();
    }

    onChange(e) {
        this.setState({file: e.target.files[0]});
    }

    async onSubmit(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'timeout': 300000
            }
        }

        await this.setState({importing: true, showError: false});
        let axios = await axiosInstance();
        await axios.post(`/PBM/import/?pbmName=${this.formRef.current.pbm_name.value}&clientId=${this.props.client.client_id}`, formData, config)
            .then(async result => {
                if(result.status === 200) {
                    this.setState({importing: false, showSuccess: true});
                    this.props.GetPBMAction(this.props.client.client_id);
                } else {
                    this.setState({showError: true});
                }
            })
            .catch(error => {
                this.setState({showError: true, importing: false});
            });
    }

    render() {
        return (
            <div className={"vendorImportContainer"}>
                <MDBAlert show={this.state.showError} className='w-100' color='danger'>
                    There was an error importing this pricing sheet.
                </MDBAlert>
                <MDBAlert show={this.state.showSuccess} className='w-100' color='Success'>
                    Success! Drugs were successfully imported.
                </MDBAlert>
                <form onSubmit={(e) => this.onSubmit(e)} ref={this.formRef}>
                    <div>
                    </div>
                    <>
                        <MDBInputGroup>
                            <MDBInputGroupText>PBM Name</MDBInputGroupText>
                            <MDBInputGroupElement name="pbm_name" type='text' required placeholder="PBM Name" />
                        </MDBInputGroup>
                    </>
                    <div className={"fileUpload"}>
                        <label className="form-label" htmlFor="customFile">Choose an xlsx file to import</label>
                        <input type="file" name={"file"} className="form-control" id="customFile" onChange={this.onChange}/>
                    </div>
                    <MDBNavbar className={'vendorInfoNav'}>
                        <MDBBtn disabled={this.state.showSuccess} outline className={"saveButton"} color="success" type='submit'>
                            {!this.state.importing ? "Import"
                                :
                                <MDBSpinner color='success'>
                                    <span className='visually-hidden'>Importing...</span>
                                </MDBSpinner>}
                        </MDBBtn>
                    </MDBNavbar>
                </form>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const boundActions = bindActionCreators({ GetPBMAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        pbm: state.pbm,
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(ImportPBMView));
