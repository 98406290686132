import types from '../types';

export const initialState = [];

export default function vendorPricingReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_VENDOR_PRICING:
            const vendorPricing = action.payload;
            if(action.payload !== null) {
                return vendorPricing;
            } else return state;
        default:
            return state;
    }
};
