import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import GetVendorAction from "../../redux/actions/GetVendorAction";
import {
  MDBAlert,
  MDBBtn,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBInputGroupText, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle,
  MDBNavbar, MDBSpinner, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";

import ImportPricingView from "./ImportPricingView";
import GetVendorPricingAction from "../../redux/actions/GetVendorPricingAction";
import VendorPricing from "./VendorPricing";
import axios from "axios";
import FDAView from "./FDAView";
import AuditList from "../../components/AuditList";
import GetPricingAuditAction from "../../redux/actions/GetPricingAuditAction";
import GetVendorAuditAction from "../../redux/actions/GetVendorAuditAction";


  class VendorView extends Component {

    constructor(props) {
      super(props);
      this.state = {
        editMode: false,
        activeTab: 0,
        effectiveDate: "",
        showPriceImport: false,
        user: this.props.msalContext.accounts[0].name,
        modalOpen: false,
        loading: false,
        fdaData: null,
        selectedDrug: null,
        showAlert: false
      }
      this.togglePricingImport = this.togglePricingImport.bind(this);
      this.formRef = React.createRef();
      this.searchRef = React.createRef();
      this.toggleModal = this.toggleModal.bind(this);
      this.onSearch = this.onSearch.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
      const vendorId = this.props.match.params.id;
      await this.props.GetVendorAction(vendorId);
      await this.props.GetVendorAuditAction(vendorId);
      await this.props.GetVendorPricingAction(vendorId);
      await this.props.GetPricingAuditAction(vendorId);
      if(parseInt(this.props.match.params.id) === this.props.vendor.vendor_id){
        document.querySelector('.datatable-pagination-left').setAttribute('type', 'button');
        document.querySelector('.datatable-pagination-right').setAttribute('type', 'button');
      }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
      if(this.props.match.params.id !== prevProps.match.params.id) {
        const vendorId = this.props.match.params.id;
        await this.props.GetVendorAction(vendorId);
        await this.props.GetVendorAuditAction(vendorId);
        await this.props.GetVendorPricingAction(vendorId);
        await this.props.GetPricingAuditAction(vendorId);
        if(this.props.match.params.id === this.props.vendor.vendor_id){
          document.querySelector('.datatable-pagination-left').setAttribute('type', 'button');
          document.querySelector('.datatable-pagination-right').setAttribute('type', 'button');
        }
      }
    }

    handleBasicClick(value) {
      if (value === this.state.activeTab) {
        return;
      }

      this.setState({activeTab: value});
    };

    get vendorInfo() {
      const vendor = this.props.vendor;
      return (
        <>
          <MDBInputGroup>
            <MDBInputGroupText>Name</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_name" type='text' placeholder="Vendor Name" defaultValue={vendor.vendor_name}
                                  disabled={!this.state.editMode}/>
          </MDBInputGroup>
          <MDBInputGroup>
            <MDBInputGroupText>Phone</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_phone" type='tel' placeholder="Vendor's Phone" defaultValue={vendor.vendor_phone}
                                  disabled={!this.state.editMode}/>
          </MDBInputGroup>
          <MDBInputGroup>
            <MDBInputGroupText>Email</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_email" type='email' placeholder="Vendor's Email" defaultValue={vendor.vendor_email}
                                  disabled={!this.state.editMode}/>
          </MDBInputGroup>
          <div className={"sectionedContainer"}>
            <div className={"sectionOne"}>
              <MDBInputGroup>
                <MDBInputGroupText>Address 1</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_address_1" type='text' placeholder="Address 1" defaultValue={vendor.vendor_address_1}
                                      disabled={!this.state.editMode}/>
              </MDBInputGroup>
              <MDBInputGroup>
                <MDBInputGroupText>Address 2</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_address_2" type='text' placeholder="Address 2" defaultValue={vendor.vendor_address_2}
                                      disabled={!this.state.editMode}/>
              </MDBInputGroup>
            </div>
            <div className={"sectionTwo"}>
              <MDBInputGroup>
                <MDBInputGroupText>City</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_city" type='text' placeholder="City" defaultValue={vendor.vendor_city}
                                      disabled={!this.state.editMode}/>
              </MDBInputGroup>
              <MDBInputGroup>
                <MDBInputGroupText>State</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_state"  type='text' placeholder="State" defaultValue={vendor.vendor_state}
                                      disabled={!this.state.editMode}/>
              </MDBInputGroup>
              <MDBInputGroup>
                <MDBInputGroupText>Zip</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_zip" type='text' placeholder="Zip" defaultValue={vendor.vendor_zip}
                                      disabled={!this.state.editMode}/>
              </MDBInputGroup>
            </div>
          </div>
        </>
      )
    }

    get vendorContactInfo() {
      const vendor = this.props.vendor;
      return (
        <>
          <div className={"sectionedContainer"}>
            <div className={"sectionOne"}>
              <MDBInputGroup>
                <MDBInputGroupText>First Name</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_contact_first_name" type='text' placeholder="Contact First Name" defaultValue={vendor.vendor_contact_first_name} disabled={!this.state.editMode}/>
              </MDBInputGroup>
              <MDBInputGroup>
                <MDBInputGroupText>Last Name</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_contact_last_name" type='text' placeholder="Contact Last Name" defaultValue={vendor.vendor_contact_last_name} disabled={!this.state.editMode}/>
              </MDBInputGroup>
            </div>
            <div className={"sectionTwo"}>
              <MDBInputGroup>
                <MDBInputGroupText>Email</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_contact_email" type='email' placeholder="Contact Email"  defaultValue={vendor.vendor_contact_email} disabled={!this.state.editMode}/>
              </MDBInputGroup>
              <MDBInputGroup>
                <MDBInputGroupText>Phone</MDBInputGroupText>
                <MDBInputGroupElement name="vendor_contact_phone" type='text' placeholder="Contact Phone" defaultValue={vendor.vendor_contact_phone} disabled={!this.state.editMode}/>
              </MDBInputGroup>
            </div>
          </div>
        </>
      )
    }

    get vendorBankInfo() {
      const vendor = this.props.vendor;
      return (
        <>
          <MDBInputGroup>
            <MDBInputGroupText>Vendor Bank Name</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_bank_name" type='text' placeholder="Bank Name" defaultValue={vendor.vendor_bank_name} disabled={!this.state.editMode}/>
          </MDBInputGroup>
          <MDBInputGroup>
            <MDBInputGroupText>Routing Number</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_bank_routing_number" type='text' placeholder="Routing Number" defaultValue={vendor.vendor_bank_routing_number} disabled={!this.state.editMode}/>
          </MDBInputGroup>
          <MDBInputGroup>
            <MDBInputGroupText>Account Number</MDBInputGroupText>
            <MDBInputGroupElement name="vendor_bank_account_number" type='text' placeholder="Account Number" defaultValue={vendor.vendor_bank_account_number} disabled={!this.state.editMode}/>
          </MDBInputGroup>
        </>
      )
    }

    importPricingModal() {
      return (
        <MDBModal show={this.state.showPriceImport} tabIndex='-1'>
          <MDBModalDialog size={"lg"}>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Import vendor pricing...</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={this.togglePricingImport}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <ImportPricingView vendor={this.props.vendor}/>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )
    }

    async onSubmit(e) {
      e.preventDefault();
      const currentVendor = this.props.vendor;

      let updatedVendor = {
        "vendor_id": currentVendor.vendor_id || null,
        "vendor_name": this.formRef.current.vendor_name.value || null,
        "vendor_address_1": this.formRef.current.vendor_address_1.value || null,
        "vendor_address_2": this.formRef.current.vendor_address_2.value || null,
        "vendor_city": this.formRef.current.vendor_city.value || null,
        "vendor_state": this.formRef.current.vendor_state.value || null,
        "vendor_zip": this.formRef.current.vendor_zip.value || null,
        "vendor_phone": this.formRef.current.vendor_phone.value || null,
        "vendor_email": this.formRef.current.vendor_email.value || null,
        "vendor_contact_first_name": this.formRef.current.vendor_contact_first_name.value || null,
        "vendor_contact_last_name": this.formRef.current.vendor_contact_last_name.value || null,
        "vendor_contact_email": this.formRef.current.vendor_contact_email.value || null,
        "vendor_contact_phone": this.formRef.current.vendor_contact_phone.value || null,
        "vendor_bank_name": this.formRef.current.vendor_bank_name.value || null,
        "vendor_bank_routing_number": this.formRef.current.vendor_bank_routing_number.value || null,
        "vendor_bank_account_number": this.formRef.current.vendor_bank_account_number.value || null,
        "active": true,
      }

      let axios = await axiosInstance();
      await axios.post(`/vendors?changedBy=${this.state.user}`, updatedVendor, )
        .then(result => {
          this.setState({editMode: false});
          this.props.GetVendorAction(result.data.vendor_id);
          this.formRef.current.reset()
        });
    }

    onCancel() {
      this.formRef.current.reset()
      this.setState({editMode: false, effectiveDate: null});
    }

    togglePricingImport = async () => {
      this.setState({showPriceImport: !this.state.showPriceImport});
      await this.props.GetVendorPricingAction(this.props.vendor.vendor_id);
    }

    selectNonMatchedDrug(index) {
      const drug = this.props.vendorPricing[index];
      this.setState({selectedDrug: drug, modalOpen: true});
    }

    toggleModal = () => {
      this.setState({modalOpen: !this.state.modalOpen, fdaData: null});
      this.searchRef.current.reset();
    }

    modalSuccess = async () => {
      this.setState({modalOpen: !this.state.modalOpen});
      await this.props.GetVendorsAction();
    }

    onSearch = async (e) => {
      e.preventDefault()
      const formValue = this.searchRef.current.search.value;
      await axios.get(`https://api.fda.gov/drug/ndc.json?api_key=xxKUI6jmXw8UaQrp92PYtT3UVYRD9Erh8bjr5Zne&search=${formValue}&limit=100`)
          .then(async result => this.setState({fdaData: result.data}))
          .catch(error => this.setState({showAlert: true}));
    }

    searchModal() {
      return (
          <MDBModal className={"fdaModal modal-static"} staticBackdrop={true} show={this.state.modalOpen} tabIndex='-1'>
            <MDBModalDialog size={"xl"}>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Search for Drug</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' role={'button'} onClick={this.toggleModal}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBAlert show={this.state.showAlert} className='w-100' color='danger'>
                    There was an error searching the FDA's database.
                  </MDBAlert>
                  <div className={"sectionedContainer"}>
                    <div className={"sectionOne"}>
                      <MDBInputGroup>
                        <MDBInputGroupText>Name</MDBInputGroupText>
                        <MDBInputGroupElement name="drug_name" type='text'
                                              defaultValue={this.state.selectedDrug != null ? this.state.selectedDrug.name : ''}
                                              disabled={true}/>
                      </MDBInputGroup>
                      <MDBInputGroup>
                        <MDBInputGroupText>Strength</MDBInputGroupText>
                        <MDBInputGroupElement name="drug_strength" type='text'
                                              defaultValue={this.state.selectedDrug != null ? this.state.selectedDrug.strength : ''}
                                              disabled={true}/>
                      </MDBInputGroup>
                      <MDBInputGroup>
                        <MDBInputGroupText>Quantity</MDBInputGroupText>
                        <MDBInputGroupElement name="drug_quantity" type='text'
                                              defaultValue={this.state.selectedDrug != null ? this.state.selectedDrug.quantity : ''}
                                              disabled={true}/>
                      </MDBInputGroup>
                    </div>
                    <div className={"sectionTwo"}>
                      <MDBInputGroup>
                        <MDBInputGroupText>Unit</MDBInputGroupText>
                        <MDBInputGroupElement name="drug_unit" type='text'
                                              defaultValue={this.state.selectedDrug != null ? this.state.selectedDrug.unit : ''}
                                              disabled={true}/>
                      </MDBInputGroup>
                      <MDBInputGroup>
                        <MDBInputGroupText>NDC</MDBInputGroupText>
                        <MDBInputGroupElement name="drug_ndc" type='text'
                                              defaultValue={this.state.selectedDrug != null ? this.state.selectedDrug.package_ndc : ''}
                                              disabled={true}/>
                      </MDBInputGroup>
                    </div>
                  </div>
                  <form onSubmit={this.onSearch} ref={this.searchRef}>
                    <MDBInputGroup className='mb-3'>
                      <MDBInputGroupElement name={"search"} placeholder="Drug Name" type='text'/>
                      <MDBBtn outline type={"submit"}>Search</MDBBtn>
                    </MDBInputGroup>
                  </form>
                  <>
                    <FDAView fdaData={this.state.fdaData} selectedDrug={this.state.selectedDrug}
                             onSuccess={this.toggleModal}/>
                  </>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
      )
    }

    render() {

      return (
          parseInt(this.props.match.params.id) !== this.props.vendor.vendor_id ?
              <MDBSpinner color='success'/>
          :
        <div className={"vendorInfoContainer"}>
          <form onSubmit={this.onSubmit} ref={this.formRef}>
            <div>
              <MDBNavbar className={'vendorInfoNav'}>
                {!this.state.editMode ?
                  <>
                    <>
                      <MDBBtn outline className={"editButton"} color="success" type='button'
                              onClick={() => this.togglePricingImport()}>Import Pricing</MDBBtn>
                    </>
                    <>
                  <MDBBtn outline className={"editButton"} color="warning" type='button'
                          onClick={() => this.setState({editMode: true})}>Edit</MDBBtn>
                    </>
                  </>
                  :
                  <>
                    <MDBBtn outline className={"editButton"} color="danger" type='button'
                            onClick={() => this.onCancel()}>Cancel</MDBBtn>
                    <MDBBtn outline className={"saveButton"} color="success" type='submit'>Save</MDBBtn>
                  </>
                }
              </MDBNavbar>
            </div>
            <div className={"tabContainer"}>
              <MDBTabs>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(0)} active={this.state.activeTab === 0}>
                    Vendor Info
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(1)} active={this.state.activeTab === 1}>
                    Vendor Contact
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(2)} active={this.state.activeTab === 2}>
                    Bank Info
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(3)} active={this.state.activeTab === 3}>
                    Drug Pricing
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(4)} active={this.state.activeTab === 4}>
                    Pricing Audit
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => this.handleBasicClick(5)} active={this.state.activeTab === 5}>
                    Vendor Audit
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                <MDBTabsPane show={this.state.activeTab === 0}>{this.vendorInfo}</MDBTabsPane>
                <MDBTabsPane show={this.state.activeTab === 1}>{this.vendorContactInfo}</MDBTabsPane>
                <MDBTabsPane show={this.state.activeTab === 2}>{this.vendorBankInfo}</MDBTabsPane>
                <MDBTabsPane show={this.state.activeTab === 3}><VendorPricing selectNonMatchedDrug={(index) => this.selectNonMatchedDrug(index)}/></MDBTabsPane>
                <MDBTabsPane show={this.state.activeTab === 4}><AuditList auditList={this.props.pricingAuditList}/></MDBTabsPane>
                <MDBTabsPane show={this.state.activeTab === 5}><AuditList auditList={this.props.vendorAudit}/></MDBTabsPane>
              </MDBTabsContent>
            </div>
          </form>
          {this.importPricingModal()}
          {this.searchModal()}
        </div>
      )
    }
  }
const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators({ GetVendorAction, GetVendorPricingAction, GetPricingAuditAction, GetVendorAuditAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    vendor: state.vendor,
    vendorAudit: state.vendorAudit,
    vendorPricing: state.vendorPricing,
    pricingAuditList: state.vendorPricingAudit
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(withMsal(VendorView));
