import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";
import axiosInstance from '../../util/axios';
import {
    MDBAlert,
    MDBBtn,
    MDBDatatable, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog, MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle, MDBNavbar,
} from "mdb-react-ui-kit";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import GetAllPricingAction from "../../redux/actions/GetAllPricingAction";
import baseUrl from "../../redux/baseUrl";
import GetRBSPricingAction from "../../redux/actions/GetRBSPricingAction";
import GetRBSPriceComparisonAction from "../../redux/actions/GetRBSPriceComparisonAction";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faList, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import AddRBSPriceView from "./AddRBSPriceView";


class RBSPricing extends Component {
    async componentDidMount() {
        await this.props.GetAllPricingAction();
    }

    constructor(props) {
        super(props);
        this.state = {
            rbsPricing: [],
            compareModalOpen: false,
            addModalOpen: false,
            deleteModalOpen: false,
            editPriceId: 0,
            deletePriceId: 0
        }

        this.generatePricing = this.generatePricing.bind(this);
        this.toggleCompareModal = this.toggleCompareModal.bind(this);
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.comparePrice = this.comparePrice.bind(this);
        this.onSelectDrug = this.onSelectDrug.bind(this);
        this.editPrice = this.editPrice.bind(this);
        this.exportPrices = this.exportPrices.bind(this);
    }

    get tableData() {
        let columns = [
            {
                label: 'Drug Name',
                field: 'drug_name',
                sort: true,
                width: 150
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 150
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 50
            },
            {
                label: 'Vendor Price',
                field: 'price',
                sort: true,
                width: 75
            },
            {
                label: 'RBS Price',
                field: 'rbs_price',
                sort: true,
                width: 75
            },
            {
                label: 'Vendor Name',
                field: 'vendor_name',
                sort: true,
                width: 150
            },
            {
                label: 'Actions',
                field: 'actions',
                sort: true,
                width: 250
            },
        ];
        let prices = this.props.rbsPricing.length === 0 ? this.state.rbsPricing : this.props.rbsPricing;
        let rows = prices.map((price, index) => {
            return {
                drug_name: price.name || "",
                strength: price.strength || "",
                quantity: price.quantity || "",
                price: price.price || "",
                ndc: price.package_ndc || "",
                vendor_name: price.vendor_name,
                rbs_price: this.state.editPriceId === price.vendor_price_id ? <MDBInput key={`${price.vendor_price_id}`} name={`${price.vendor_price_id}`} id={`${price.vendor_price_id}`} type='text' defaultValue={price.rbs_price}/> : price.rbs_price,
                actions: <>
                    <MDBBtn title={"See Other Clients"} outline size={"sm"} color={"warning"} role={'button'} key={`${price.vendor_price_id} compare`} className={"projectLink"}
                                 onClick={() => this.comparePrice(price)}
                                 style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faList}/></MDBBtn>
                    <MDBBtn title={"Delete Price"} outline size={"sm"} color={"warning"} role={'button'} key={`${price.vendor_price_id} delete`} className={"projectLink"}
                            onClick={() => this.toggleDeleteModal(price)}
                            style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faTrash}/></MDBBtn>
                    {this.state.editPriceId === price.vendor_price_id ? <MDBBtn title={"Save Price"} outline size={"sm"} color={"success"} role={'button'} key={`${price.vendor_price_id} edit`} className={"projectLink"}
                                                                                onClick={() => this.savePrice(price)}
                                                                                style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faSave}/></MDBBtn> :
                        <MDBBtn title={"Edit Price"} outline size={"sm"} color={"warning"} role={'button'} key={`${price.vendor_price_id} edit`} className={"projectLink"}
                                onClick={() => this.editPrice(price)}
                                style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faEdit}/></MDBBtn>}
                    </>

            }
        });
        return {columns, rows};
    }

    async onSelectDrug(drug) {
        drug.rbs_price = 0.00;
        drug.client_id = this.props.clientId;
        let axios = await axiosInstance();
        await axios.put(`${baseUrl}/RBSPrices/create`, drug, )
            .then(async result => {
                if(result.status === 200) {
                    await this.props.GetRBSPricingAction(this.props.clientId);
                }
            });
    }

    async comparePrice(price) {
        await this.props.GetRBSPriceComparisonAction(price.vendor_price_id, price.client_id);
        this.setState({compareModalOpen: true});
    }

    async savePrice(price) {
        let newPrice = document.getElementById(`${price.vendor_price_id}`).value;
        let axios = await axiosInstance();
        axios.post(`${baseUrl}/RBSPrices/update/${price.rbs_price_id}?price=${newPrice}`, {}, )
            .then(async result => {
                await this.props.GetRBSPricingAction(this.props.clientId);
                this.setState({editPriceId: 0});
            })
    }

    async deletePrice() {
        let axios = await axiosInstance();
        axios.delete(`${baseUrl}/RBSPrices/${this.state.deletePriceId}`, )
            .then(result => {
                if(result.data) {
                    this.setState({deleteModalOpen: false, deletePriceId: 0});
                    this.props.GetRBSPricingAction(this.props.clientId);
                }
            })
    }

    editPrice(price) {
        this.setState({editPriceId: price.vendor_price_id});
    }

    toggleCompareModal = () => {
        this.setState({compareModalOpen: !this.state.compareModalOpen});
    }

    toggleDeleteModal = (price) => {
        this.setState({deleteModalOpen: !this.state.deleteModalOpen, deletePriceId: price.rbs_price_id});
    }

    toggleAddModal = () => {
        this.setState({addModalOpen: !this.state.addModalOpen});
    }

    addModal() {
        return (
            <MDBModal className={"fdaModal modal-static"} staticBackdrop={true} show={this.state.addModalOpen} tabIndex='-1'>
                <MDBModalDialog size={"xl"}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add RBS Price</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' role={'button'} onClick={this.toggleAddModal}/>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <>
                            <AddRBSPriceView onSelectDrug={this.onSelectDrug} ref={this.ref} rbsPricing={this.props.rbsPricing} />
                            </>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    }

    async exportPrices() {
        let file;
        let axios = await axiosInstance();
        await axios.get(`${baseUrl}/RBSPrices/${this.props.client.client_id}/priceComparison`, {responseType: "blob"})
           .then(result => {
               console.log(result)
               file = result.data;
               let link=document.createElement('a');
               link.href=window.URL.createObjectURL(file);
               link.download=`${this.props.client.client_name}.xlsx`;
               link.click();
           })
    }

    compareModal() {
        return (
            <MDBModal className={"fdaModal modal-static"} staticBackdrop={true} show={this.state.compareModalOpen} tabIndex='-1'>
                <MDBModalDialog size={"sm"}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Compare Client Prices</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' role={'button'} onClick={this.toggleCompareModal}/>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {this.props.rbsPriceComparison.length > 0 ? this.props.rbsPriceComparison.map(price => (
                                <dl>
                                    <dt>{price.client_name}</dt>
                                    <dd>${price.rbs_price}</dd>
                                </dl>
                            ))
                            :
                            <MDBAlert className={"emptyAlert"} show color={"warning"}>There aren't any other client's using this drug.</MDBAlert>}
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    }

    deleteModal() {
        return (
            <MDBModal show={this.state.deleteModalOpen} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Delete Price</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggleDeleteModal}/>
                        </MDBModalHeader>
                        <MDBModalBody>Are you sure you want to delete this price?</MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={this.toggleDeleteModal}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn onClick={() => this.deletePrice()}>Delete</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    }


    async generatePricing() {
        let rbsPricing = [];
        await this.props.pbmPricing.forEach(price => {
            let name = price.drug_name.split(" ");
            let matchedDrug = this.props.vendorPrices.find(vendorPrice => vendorPrice.name.toLowerCase().includes(name[0].toLowerCase()))
            if(matchedDrug !== undefined){
                matchedDrug.client_id = this.props.clientId;
                matchedDrug.rbs_price = 0.00;
                rbsPricing.push(matchedDrug);
            }
        });
        this.setState({rbsPricing})
    }

    render() {
        return (
            <>
                <>
                    <MDBNavbar className={'clientInfoNav'}>
                        <MDBBtn outline className={"saveButton"} color="primary" type='button'
                                onClick={this.toggleAddModal}>+ New RBS Price</MDBBtn>
                        <MDBBtn outline className={"saveButton"} color="Secondary" type='button'
                                onClick={this.exportPrices}>Export Price Comparison</MDBBtn>

                    </MDBNavbar>
                </>
                <>
                    <MDBDatatable
                        striped
                        bordered
                        advancedData
                        search
                        fixedHeader
                        data={this.tableData}
                    />
                </>
                {this.compareModal()}
                {this.addModal()}
                {this.deleteModal()}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const boundActions = bindActionCreators({ GetAllPricingAction, GetRBSPricingAction, GetRBSPriceComparisonAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        vendorPrices: state.allPricing,
        rbsPriceComparison: state.rbsPriceComparison,
        client: state.client
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(RBSPricing));
