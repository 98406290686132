import types from '../types';

export const initialState = [];

export default function rbsPriceComparisonReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_RBS_PRICE_COMPARISON:
            const rbsPriceComparison = action.payload;
            if(action.payload !== null) {
                return rbsPriceComparison;
            } else return state;
        default:
            return state;
    }
};
