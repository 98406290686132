import types from '../types';
import baseUrl from '../baseUrl';

const GetVendorAudit = (vendorId) => ({
    type: types.GET_VENDOR_AUDIT,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/vendors/audit/${vendorId}`,
    },
    options: {
        method: 'GET'
    }
})

export default GetVendorAudit;
