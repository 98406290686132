import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import './style.scss';
import {
  MDBBreadcrumb, MDBBreadcrumbItem,
  MDBBtn,
  MDBCollapse, MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand, MDBNavbarItem, MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import GetVendorsAction from "../redux/actions/GetVendorsAction";
import GetClientsAction from "../redux/actions/GetClientsAction";

class Menu extends Component {

  constructor(props) {
    super(props);

    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
    this.checkCase = this.checkCase.bind(this);
  }

  async componentDidMount() {
     await this.props.GetClientsAction();
     await this.props.GetVendorsAction();
  }

  getBreadCrumbs = () => {
    const pathArray = this.props.history.location.pathname.split("/").splice(1);
    let path = `/${pathArray[0]}`
    let breadCrumbs = [<MDBBreadcrumbItem key={pathArray[0]}>
      <a href={path}>{pathArray[0].charAt(0).toUpperCase() + pathArray[0].substring(1)}</a>
    </MDBBreadcrumbItem>];

    pathArray.forEach((crumb, index) => {
      if (index > 0) {

        path += `/${crumb}`;

        if(index === pathArray.length - 1) {
          switch(true) {
            case pathArray.includes("clients"):
              let client = this.props.clients.find(client => client.client_id === parseInt(pathArray[index]));
              if(client !== undefined) {
                crumb = client.client_name;
              }
              break;
            case pathArray.includes("vendors"):
              let vendor = this.props.vendors.find(vendor => vendor.vendor_id === parseInt(pathArray[index]));
              if( vendor !== undefined) {
                crumb = vendor.vendor_name
              }
              break;
            default:
              break;
          }
        }
        breadCrumbs.push(
            <MDBBreadcrumbItem key={crumb}>
              <a href={path}>{crumb}</a>
            </MDBBreadcrumbItem>
        );
      }
    });
    return breadCrumbs;
  }

  checkCase = (ch) => {
    if (!isNaN(ch * 1)){
      return false;
    }
    else {
      if (ch === ch.toUpperCase()) {
        return true;
      }
      if (ch === ch.toLowerCase()){
        return false;
      }
    }
  }

  render() {
    return (
      <div className={"menuContainer"}>
        <MDBNavbar expand='lg' light bgColor='light'>
          <MDBContainer fluid>
            <MDBNavbarBrand href='/home'>Benefits Gateway</MDBNavbarBrand>

            <MDBNavbarToggler
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() =>{}}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>

            <MDBCollapse navbar>
              <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                <MDBNavbarItem>
                  <MDBNavbarLink href='/home'>
                    Home
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href='/clients'>Clients</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href='/vendors'>Vendors</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href='/drugs'>Drugs</MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink href='/pbm'>PBM</MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>

              <form className='d-flex input-group w-auto'>
                <div className={'logoutContainer'}>
                  <span>{this.props.msalContext.accounts[0].name}</span>
                  <MDBBtn color='primary' onClick={() => this.props.msalContext.instance.logout()}>Logout</MDBBtn>
                </div>
              </form>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        <div className={"breadCrumbsContainer"}>
          <MDBBreadcrumb>
            {this.getBreadCrumbs()}
          </MDBBreadcrumb>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators({ GetClientsAction, GetVendorsAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    vendors: state.vendors
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withMsal(Menu));
