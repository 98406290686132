const types = {
  GET_CLIENTS: 'GET_CLIENTS',
  GET_CLIENT: 'GET_CLIENT',
  SET_CLIENT_ID: 'SET_CLIENT_ID',
  GET_VENDORS: 'GET_VENDORS',
  GET_VENDOR: 'GET_VENDOR',
  SET_VENDOR_ID: 'SET_VENDOR_ID',
  GET_RECONCILIATIONS: 'GET_RECONCILIATIONS',
  GET_VENDOR_PRICING: 'GET_VENDOR_PRICING',
  GET_VENDOR_PRICING_AUDIT: 'GET_VENDOR_PRICING_AUDIT',
  GET_VENDOR_AUDIT: 'GET_VENDOR_AUDIT',
  GET_CLIENT_AUDIT: 'GET_CLIENT_AUDIT',
  GET_ALL_PRICING: 'GET_ALL_PRICING',
  GET_ALL_PBM: 'GET_ALL_PBM',
  GET_PBM: 'GET_PBM',
  GET_RBS_PRICING: 'GET_RBS_PRICING',
  GET_RBS_PRICE_COMPARISON: 'GET_RBS_PRICE_COMPARISON',
  GET_API_TOKEN: 'GET_API_TOKEN'


}

export default types;
