import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";
import './style.scss';
import {
    MDBBtn,
    MDBDatatable
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";


class FDAView extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.formRef = React.createRef();
        this.addDrug = this.addDrug.bind(this);
    }

    get tableData() {
        let columns = [
            {
                label: 'NDC',
                field: 'package_ndc',
                sort: true,
                width: 150
            },
            {
                label: 'Brand Name',
                field: 'brand_name',
                sort: true,
                width: 100
            },
            {
                label: 'Generic Name',
                field: 'generic_name',
                sort: true,
                width: 100
            },
            {
                label: 'Package Description',
                field: 'description',
                sort: true,
                width: 500
            }
        ];
        let rows = []

        if(this.props.fdaData !== null){
            this.props.fdaData.results.forEach((drug, index) => {
                return drug.packaging.forEach(pkg => {
                    rows.push({
                        package_ndc: pkg.package_ndc || "",
                        brand_name: drug.brand_name || "",
                        generic_name: drug.generic_name || "",
                        description: pkg.description || ""
                    });
                });
            });
        }
        return {columns, rows};
    }

    addDrug = async (pkg, drug) => {
        let newDrug = {
            package_ndc: pkg.package_ndc,
            brand_name: drug.brand_name,
            generic_name: drug.generic_name,
            dosage_form: drug.dosage_form,
            packaging_description: pkg.description
        }
        let axios = await axiosInstance();
        await axios.put(`/drugs`, newDrug)
            .then(async result => {
                if(result.status === 200) {
                    this.props.onSuccess();
                }
            })
            .catch(error => this.props.onError(error));
    }

    render() {
        return (
            <MDBDatatable
                striped
                bordered
                advancedData
                fixedHeader
                data={this.tableData}
            />
        )
    }
}

export default withRouter(withMsal(FDAView));
