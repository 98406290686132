import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import moment from 'moment';
import GetClientAction from "../../redux/actions/GetClientAction";
import {
  MDBAlert,
  MDBBtn,
  MDBCheckbox, MDBDatepicker,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBInputGroupText, MDBNavbar,
  MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";


class AddClientView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      effectiveDate: ""
    }
    this.setDate = this.setDate.bind(this);
    this.formRef = React.createRef();
  }

  handleBasicClick(value) {
    if (value === this.state.activeTab) {
      return;
    }

    this.setState({activeTab: value});
  };

  setDate(date) {
    this.setState({effectiveDate: date});
  }

  get clientInfo() {
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Name</MDBInputGroupText>
          <MDBInputGroupElement name="client_name" type='text' placeholder="Client Name" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Phone</MDBInputGroupText>
          <MDBInputGroupElement name="client_phone" type='tel' placeholder="Client's Phone" />
        </MDBInputGroup>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>Address 1</MDBInputGroupText>
              <MDBInputGroupElement name="client_address_1" type='text' placeholder="Address 1" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Address 2</MDBInputGroupText>
              <MDBInputGroupElement name="client_address_2" type='text' placeholder="Address 2" />
            </MDBInputGroup>
            <MDBInputGroup>
                <MDBDatepicker inputToggle title="effective_date" labelText={"Effective Date"} format={'mm/dd/yyyy'}
                               value={this.state.effectiveDate === "" ? moment().format('MM/DD/YYYY') : this.state.effectiveDate}
                               setValue={this.setDate}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBCheckbox name="prospect" id='flexCheckChecked' label='Prospect' />
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>City</MDBInputGroupText>
              <MDBInputGroupElement name="client_city" type='text' placeholder="City" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>State</MDBInputGroupText>
              <MDBInputGroupElement name="client_state"  type='text' placeholder="State" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Zip</MDBInputGroupText>
              <MDBInputGroupElement name="client_zip" type='text' placeholder="Zip" />
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get clientContactInfo() {
    return (
      <>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>First Name</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_first_name" type='text' placeholder="Contact First Name" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Last Name</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_last_name" type='text' placeholder="Contact Last Name" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Role</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_role" type='text' placeholder="Contact's Role" />
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>Email</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_email" type='text' placeholder="Contact Email" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Phone</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_phone" type='text' placeholder="Client Contact Phone" />
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get clientBenefitInfo() {
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Broker</MDBInputGroupText>
          <MDBInputGroupElement name="broker_name" type='text' placeholder="Broker" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Broker Email</MDBInputGroupText>
          <MDBInputGroupElement name="broker_email" type='email' placeholder="Broker's Email" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Broker Phone</MDBInputGroupText>
          <MDBInputGroupElement name="broker_phone" type='tel' placeholder="Broker's Phone" />
        </MDBInputGroup>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>PBM</MDBInputGroupText>
              <MDBInputGroupElement name="pbm" type='text' placeholder="PBM" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>TPA</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_name" type='text' placeholder="TPA" />
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>First Name</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_first_name" type='text' placeholder="TPA Contact First Name" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Last Name</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_last_name" type='text' placeholder="TPA Contact Last Name" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Email</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_email" ntype='email' placeholder="TPA Email" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Phone</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_phone" type='tel' placeholder="TPA Phone" />
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault();

    let updatedClient = {
      "client_id": null,
      "client_name": this.formRef.current.client_name.value || null,
      "client_address_1": this.formRef.current.client_address_1.value || null,
      "client_address_2": this.formRef.current.client_address_2.value || null,
      "client_city": this.formRef.current.client_city.value || null,
      "client_state": this.formRef.current.client_state.value || null,
      "client_zip": this.formRef.current.client_zip.value || null,
      "client_phone": this.formRef.current.client_phone.value || null,
      "client_contact_first_name": this.formRef.current.client_contact_first_name.value || null,
      "client_contact_last_name": this.formRef.current.client_contact_last_name.value || null,
      "client_contact_email": this.formRef.current.client_contact_email.value || null,
      "client_contact_phone": this.formRef.current.client_contact_phone.value || null,
      "client_contact_role": this.formRef.current.client_contact_role.value || null,
      "broker_name": this.formRef.current.broker_name.value || null,
      "broker_phone": this.formRef.current.broker_phone.value || null,
      "pbm": this.formRef.current.pbm.value || null,
      "tpa_name": this.formRef.current.tpa_name.value || null,
      "tpa_contact_first_name": this.formRef.current.tpa_contact_first_name.value || null,
      "tpa_contact_last_name": this.formRef.current.tpa_contact_last_name.value || null,
      "tpa_contact_email": this.formRef.current.tpa_contact_email.value || null,
      "tpa_contact_phone": this.formRef.current.tpa_contact_phone.value || null,
      "prospect": this.formRef.current.prospect.checked,
      "active": true,
      "effective_date": this.state.effectiveDate === "" ? null : moment(this.state.effectiveDate).format("YYYY-MM-DDT04:00:00.000").toString()
    }
    let axios = await axiosInstance();
    await axios.put('/clients', updatedClient, )
      .then(result => {
        if(result.status === 200) {
          this.props.onSuccess();
          this.formRef.current.reset()
        } else {
          this.setState({showAlert: true})
        }
      })
      .catch(error => {
        this.setState({showAlert: true})
      });
  }

  render() {
    return (
      <div className={"tabContainer"}>
        <MDBAlert show={this.state.showAlert} className='w-100' color='danger'>
          There was an error creating this client.
        </MDBAlert>
        <form onSubmit={this.onSubmit} ref={this.formRef}>
          <div>
            <MDBNavbar className={'clientInfoNav'}>
                  <MDBBtn outline className={"saveButton"} color="success" type='submit'>Save</MDBBtn>
            </MDBNavbar>
          </div>
          <MDBTabs>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => this.handleBasicClick(0)} active={this.state.activeTab === 0}>
                Client Info
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => this.handleBasicClick(1)} active={this.state.activeTab === 1}>
                Client Contact
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => this.handleBasicClick(2)} active={this.state.activeTab === 2}>
                Benefits Info
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          <MDBTabsContent>
            <MDBTabsPane show={this.state.activeTab === 0}>{this.clientInfo}</MDBTabsPane>
            <MDBTabsPane show={this.state.activeTab === 1}>{this.clientContactInfo}</MDBTabsPane>
            <MDBTabsPane show={this.state.activeTab === 2}>{this.clientBenefitInfo}</MDBTabsPane>
          </MDBTabsContent>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const boundActions = bindActionCreators({ GetClientAction }, dispatch);
  return {
    ...boundActions
  }
}

export default compose(
  withRouter,
  connect(mapDispatchToProps))(withMsal(AddClientView));
