import types from '../types';

export const initialState = {};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENT:
      const client = action.payload;
      if(action.payload !== null) {
        return client;
      } else return state;
    default:
      return state;
  }
};
