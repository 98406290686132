import types from '../types';
import baseUrl from '../baseUrl';

const GetVendor = (vendorId) => ({
  type: types.GET_VENDOR,
  payload: {},
  meta: {
    type: 'apijson',
    url: `${baseUrl}/vendors/${vendorId}`,
  },
  options: {
    method: 'GET'
  }
})

export default GetVendor;
