import types from '../types';
import baseUrl from '../baseUrl';

const GetRBSPriceComparison = (vendorPriceId, clientId) => ({
    type: types.GET_RBS_PRICE_COMPARISON,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/RBSPrices/compare/${vendorPriceId}/${clientId}`,
    },
    options: {
        method: 'GET'
    }
})

export default GetRBSPriceComparison;
