import types from '../types';
import baseUrl from '../baseUrl';

const GetDrugs = () => ({
    type: types.GET_ALL_PRICING,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/vendorPricing`,
    },
    options: {
        method: 'GET'
    }
})

export default GetDrugs;
