import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import GetClientsAction from "../../redux/actions/GetClientsAction";
import './style.scss';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody, MDBModalContent, MDBModalDialog,
  MDBModalHeader, MDBModalTitle,
  MDBNavbar, MDBDatatable
} from "mdb-react-ui-kit";
import AddClientView from "./AddClientView";


class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      loading: false
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    await this.setState({loading: true});

    await this.props.GetClientsAction();
    await this.setState({loading: false});

  }

  get tableData() {
    let columns = [
      {
        label: 'Client Name',
        field: 'client_name',
        sort: true,
        width: 150
      },
      {
        label: 'Contact First Name',
        field: 'client_contact_first_name',
        sort: true,
        width: 250
      },
      {
        label: 'Contact Last Name',
        field: 'client_contact_last_name',
        sort: true,
        width: 350
      },
      {
        label: 'Contact Phone',
        field: 'client_contact_phone',
        sort: true,
        width: 150
      },
      {
        label: 'Contact Email',
        field: 'client_contact_email',
        sort: true,
        width: 150
      },
      {
        label: 'Prospect',
        field: 'prospect',
        sort: true,
        width: 150
      },
      {
        label: '',
        field: 'button',
        sort: false,
        width: 150
      }
    ];
    let rows = this.props.clients.map(client => {
      return {
        client_name: client.client_name || "",
        client_contact_first_name: client.client_contact_first_name || "",
        client_contact_last_name: client.client_contact_last_name || "",
        client_contact_phone: client.client_contact_phone || "",
        client_contact_email: client.client_contact_email || "",
        prospect: client.prospect ? 'True' : 'False',
        button: <MDBBtn outline color={"warning"} key={client.client_id} className={"projectLink"} to={`/clients/${client.client_id}`}
                      onClick={() => this.props.history.push(`/clients/${client.client_id}`)}
                      style={{textDecoration: "underline"}}>View</MDBBtn>
      }
    });
    return {columns, rows};
  }

  toggleModal = () => {
    this.setState({modalOpen: !this.state.modalOpen});
  }

  modalSuccess = async () => {
    this.setState({modalOpen: !this.state.modalOpen});
    await this.props.GetClientsAction();
  }

  addClientModal() {
    return (
      <MDBModal show={this.state.modalOpen} tabIndex='-1'>
        <MDBModalDialog size={"lg"}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add a Client</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={this.toggleModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <AddClientView onSuccess={this.modalSuccess}/>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )
  }
  render() {
    return (
      <div className={"clientList"}>
        { this.state.loading ?
          <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </>
          :
          <>
            <div>
              <MDBNavbar className={'clientInfoNav'}>
                  <MDBBtn outline className={"saveButton"} color="primary" type='button'
                          onClick={this.toggleModal}>+ New Client</MDBBtn>

              </MDBNavbar>
            </div>
            <MDBDatatable
              className={"clientTable"}
              striped
              bordered
              advancedData
              search
              fixedHeader
              data={this.tableData}
            />
          </>
        }
        {this.addClientModal()}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators({ GetClientsAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    token: state.token
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(withMsal(Clients));
