import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
    MDBBtn,
    MDBDatatable,
} from "mdb-react-ui-kit";
import './style.scss';

import GetAllPricingAction from "../../redux/actions/GetAllPricingAction";


class AddRBSPriceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            loading: false
        }
    }

    async componentDidMount() {
        await this.setState({loading: true});
        await this.props.GetAllPricingAction();
        await this.setState({loading: false});
    }

    get tableData() {
        let rbsPriceIds = this.props.rbsPricing.map(price => price.vendor_price_id);
        let columns = [
            {
                label: '',
                field: 'button',
                sort: true,
                width: 175
            },
            {
                label: 'Drug Name',
                field: 'name',
                sort: true,
                width: 300
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 150
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 150
            },
            {
                label: 'Vendor Price',
                field: 'price',
                sort: true,
                width: 150
            },
            {
                label: 'Unit',
                field: 'unit',
                sort: true,
                width: 150
            },
            {
                label: 'NDC',
                field: 'package_ndc',
                sort: true,
                width: 150
            },
            {
                label: 'Vendor',
                field: 'vendor_name',
                sort: true,
                width: 200
            }
        ];
        let rows = []
        this.props.pricing.forEach((price, index) => {
            if(!rbsPriceIds.includes(price.vendor_price_id)) {
                rows.push({
                    name: price.name || "",
                    unit: price.unit || "",
                    quantity: price.quantity || "",
                    price: price.price || "",
                    vendor_name: price.vendor_name || "",
                    strength: price.strength || "",
                    package_ndc: price.package_ndc || "",
                    button: <MDBBtn outline color={"warning"} role={'button'} key={index} className={"projectLink"}
                                    onClick={() => this.props.onSelectDrug(price)}
                                    style={{textDecoration: "underline"}}>Add Drug</MDBBtn>
                });
            }
        });
        return {columns, rows};
    }

    render() {
        return (
            <div className={"drugListContainer"}>
                {this.state.loading ?
                    <>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </>
                    :
                    <>
                        <MDBDatatable
                            striped
                            bordered
                            advancedData
                            search
                            fixedHeader
                            data={this.tableData}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const boundActions = bindActionCreators({ GetAllPricingAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        pricing: state.allPricing
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(AddRBSPriceView));
