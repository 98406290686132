import types from '../types';

export const initialState = [];

export default function allPbmReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_PBM:
            const allPbm = action.payload;
            if(action.payload !== null) {
                return allPbm;
            } else return state;
        default:
            return state;
    }
};
