import types from '../types';
import baseUrl from '../baseUrl';

const GetClients = () => ({
    type: types.GET_CLIENTS,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/clients`,
    },
    options: {
        method: 'GET'
    }
})

export default GetClients;
