import types from '../types';
import baseUrl from '../baseUrl';

const GetClient = (clientId) => ({
    type: types.GET_CLIENT,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/clients/${clientId}`,
    },
    options: {
        method: 'GET'
    }
})

export default GetClient;
