import types from '../types';
import baseUrl from '../baseUrl';

const GetVendors = () => ({
  type: types.GET_VENDORS,
  payload: {},
  meta: {
    type: 'apijson',
    url: `${baseUrl}/vendors`,
  },
  options: {
    method: 'GET'
  }
})

export default GetVendors;
