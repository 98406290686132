import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {MDBDatatable} from "mdb-react-ui-kit";

import GetAllPbmAction from "../../redux/actions/GetAllPBMPricingAction";


class PBM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    async componentDidMount() {
        await this.setState({loading: true});
        this.props.GetAllPbmAction();
        await this.setState({loading: false});
    }

    get tableData() {
        let columns = [
            {
                label: 'PBM',
                field: 'pbm',
                sort: true,
                width: 150
            },
            {
                label: 'Drug Name',
                field: 'drug_name',
                sort: true,
                width: 350
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 250
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 150
            },
            {
                label: 'Price',
                field: 'price',
                sort: true,
                width: 150
            },
            {
                label: 'Client Name',
                field: 'client',
                sort: true,
                width: 200
            }
        ];
        let rows = this.props.allPbm.map((pbm) => {
            return {
                pbm: pbm.pbm_name || "",
                drug_name: pbm.drug_name || "",
                strength: pbm.strength || "",
                quantity: pbm.quantity || "",
                price: pbm.price || "",
                client: pbm.client_name || ""
            }
        });
        return {columns, rows};
    }

    render() {
        return (
            <div className={"pbmContainer"}>
                {this.state.loading ?
                    <>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </>
                    :
                    <>
                        <MDBDatatable
                            striped
                            bordered
                            advancedData
                            search
                            fixedHeader
                            scrollx
                            data={this.tableData}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const boundActions = bindActionCreators({ GetAllPbmAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        allPbm: state.allPbm,
        clients: state.clients
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(PBM));
