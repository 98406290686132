import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import moment from 'moment';
import GetClientAction from "../../redux/actions/GetClientAction";
import {
  MDBBtn, MDBCheckbox, MDBDatepicker,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBInputGroupText, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle,
  MDBNavbar, MDBSpinner, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";
import AuditList from "../../components/AuditList";
import GetClientAuditAction from "../../redux/actions/GetClientAuditAction";
import ImportPBMView from "./ImportPBMView";
import GetPBMAction from "../../redux/actions/GetPBMAction";
import PBMPricing from "./PBMPrices";
import GetRBSPricingAction from "../../redux/actions/GetRBSPricingAction";
import RBSPrices from "./RBSPrices";


class ClientView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      activeTab: 0,
      effectiveDate: "",
      showArchiveModal: false,
      showPBMImport: false
    }
    this.setDate = this.setDate.bind(this);
    this.formRef = React.createRef();
    this.toggleArchiveModal = this.toggleArchiveModal.bind(this);
    this.togglePBMImport = this.togglePBMImport.bind(this);
  }

  get clientInfo() {
    const client = this.props.client;
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Name</MDBInputGroupText>
          <MDBInputGroupElement name="client_name" type='text' placeholder="Client Name" defaultValue={client.client_name}
                                disabled={!this.state.editMode}/>
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Phone</MDBInputGroupText>
          <MDBInputGroupElement name="client_phone" type='tel' placeholder="Client's Phone" defaultValue={client.client_phone}
                                disabled={!this.state.editMode}/>
        </MDBInputGroup>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>Address 1</MDBInputGroupText>
              <MDBInputGroupElement name="client_address_1" type='text' placeholder="Address 1" defaultValue={client.client_address_1}
                                    disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Address 2</MDBInputGroupText>
              <MDBInputGroupElement name="client_address_2" type='text' placeholder="Address 2" defaultValue={client.client_address_2}
                                    disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              {this.state.editMode ?
                <MDBDatepicker inputToggle title="effective_date" labelText={"Effective Date"} format={'mm/dd/yyyy'}
                               value={this.state.effectiveDate === "" ? moment(client.effective_date || undefined).format('MM/DD/YYYY') : this.state.effectiveDate}
                               setValue={this.setDate}/>
                :
                <>
                  <MDBInputGroupText>Effective Date</MDBInputGroupText>
                  <MDBInputGroupElement type='text' placeholder="Effective Date"
                                        defaultValue={client.effective_date !== null ? moment(client.effective_date).format('MM/DD/YYYY') : null}
                                        disabled={!this.state.editMode}/>
                </>
              }
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBCheckbox name="prospect" id='flexCheckChecked' label='Prospect' disabled={!this.state.editMode} defaultChecked={client.prospect}/>
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>City</MDBInputGroupText>
              <MDBInputGroupElement name="client_city" type='text' placeholder="City" defaultValue={client.client_city}
                                    disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>State</MDBInputGroupText>
              <MDBInputGroupElement name="client_state"  type='text' placeholder="State" defaultValue={client.client_state}
                                    disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Zip</MDBInputGroupText>
              <MDBInputGroupElement name="client_zip" type='text' placeholder="Zip" defaultValue={client.client_zip}
                                    disabled={!this.state.editMode}/>
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get clientContactInfo() {
    const client = this.props.client;
    return (
      <>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>First Name</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_first_name" type='text' placeholder="Contact First Name" defaultValue={client.client_contact_first_name} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Last Name</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_last_name" type='text' placeholder="Contact Last Name" defaultValue={client.client_contact_last_name} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Role</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_role" type='text' placeholder="Contact's Role" defaultValue={client.client_contact_role} disabled={!this.state.editMode}/>
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>Email</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_email" type='text' placeholder="Contact Email"  defaultValue={client.client_contact_email} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Phone</MDBInputGroupText>
              <MDBInputGroupElement name="client_contact_phone" type='text' placeholder="Client Contact Phone" defaultValue={client.client_contact_phone} disabled={!this.state.editMode}/>
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get clientBenefitInfo() {
    const client = this.props.client;
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Broker</MDBInputGroupText>
          <MDBInputGroupElement name="broker_name" type='text' placeholder="Broker" defaultValue={client.broker_name} disabled={!this.state.editMode}/>
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Broker Email</MDBInputGroupText>
          <MDBInputGroupElement name="broker_email" type='email' placeholder="Broker's Email" defaultValue={client.broker_email} disabled={!this.state.editMode}/>
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Broker Phone</MDBInputGroupText>
          <MDBInputGroupElement name="broker_phone" type='tel' placeholder="Broker's Phone" defaultValue={client.broker_phone} disabled={!this.state.editMode}/>
        </MDBInputGroup>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>PBM</MDBInputGroupText>
              <MDBInputGroupElement name="pbm" type='text' placeholder="PBM"  defaultValue={client.pbm} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>TPA</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_name" type='text' placeholder="TPA" defaultValue={client.tpa_name} disabled={!this.state.editMode}/>
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>First Name</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_first_name" type='text' placeholder="TPA Contact First Name" defaultValue={client.tpa_contact_first_name} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Last Name</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_last_name" type='text' placeholder="TPA Contact Last Name" defaultValue={client.tpa_contact_last_name} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Email</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_email" ntype='email' placeholder="TPA Email" defaultValue={client.tpa_contact_email} disabled={!this.state.editMode}/>
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Contact Phone</MDBInputGroupText>
              <MDBInputGroupElement name="tpa_contact_phone" type='tel' placeholder="TPA Phone" defaultValue={client.tpa_contact_phone} disabled={!this.state.editMode}/>
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  async componentDidMount() {
    const clientId = this.props.match.params.id;
    await this.props.GetClientAction(clientId);
    await this.props.GetClientAuditAction(clientId);
    await this.props.GetPBMAction(clientId);
    await this.props.GetRBSPricingAction(clientId);
  }

  async componentDidUpdate(prevProps, prevState, snapShot) {
    if(this.props.match.params.id !== prevProps.match.params.id) {
      const clientId = this.props.match.params.id;
      await this.props.GetClientAction(clientId);
      await this.props.GetClientAuditAction(clientId);
      await this.props.GetPBMAction(clientId);
      await this.props.GetRBSPricingAction(clientId);
    }
  }

  handleBasicClick(value) {
    if (value === this.state.activeTab) {
      return;
    }

    this.setState({activeTab: value});
  };

  setDate(date) {
    this.setState({effectiveDate: date});
  }

  async onSubmit(e) {
    e.preventDefault();
    const currentClient = this.props.client;

    let updatedClient = {
      "client_id": currentClient.client_id || null,
      "client_name": this.formRef.current.client_name.value || null,
      "client_address_1": this.formRef.current.client_address_1.value || null,
      "client_address_2": this.formRef.current.client_address_2.value || null,
      "client_city": this.formRef.current.client_city.value || null,
      "client_state": this.formRef.current.client_state.value || null,
      "client_zip": this.formRef.current.client_zip.value || null,
      "client_phone": this.formRef.current.client_phone.value || null,
      "client_contact_first_name": this.formRef.current.client_contact_first_name.value || null,
      "client_contact_last_name": this.formRef.current.client_contact_last_name.value || null,
      "client_contact_email": this.formRef.current.client_contact_email.value || null,
      "client_contact_phone": this.formRef.current.client_contact_phone.value || null,
      "client_contact_role": this.formRef.current.client_contact_role.value || null,
      "broker_name": this.formRef.current.broker_name.value || null,
      "broker_phone": this.formRef.current.broker_phone.value || null,
      "pbm": this.formRef.current.pbm.value || null,
      "tpa_name": this.formRef.current.tpa_name.value || null,
      "tpa_contact_first_name": this.formRef.current.tpa_contact_first_name.value || null,
      "tpa_contact_last_name": this.formRef.current.tpa_contact_last_name.value || null,
      "tpa_contact_email": this.formRef.current.tpa_contact_email.value || null,
      "tpa_contact_phone": this.formRef.current.tpa_contact_phone.value || null,
      "prospect": this.formRef.current.prospect.checked,
      "active": true,
      "effective_date": this.state.effectiveDate === "" ? null : moment(this.state.effectiveDate).format("YYYY-MM-DDT04:00:00.000").toString()
    }

    let axios = await axiosInstance();
    await axios.post('/clients', updatedClient, )
      .then(result => {
        this.setState({editMode: false});
        this.props.GetClientAction(result.data.client_id);
        this.formRef.current.reset()
      });
  }

  onCancel() {
    this.formRef.current.reset()
    this.setState({editMode: false, effectiveDate: ""});
  }

  toggleArchiveModal() {
    this.setState({showArchiveModal: !this.state.showArchiveModal})
  }

  togglePBMImport = async () => {
    this.setState({showPBMImport: !this.state.showPBMImport});
  }

  onArchive = async () => {
    let axios = await axiosInstance();
    await axios.delete(`/clients/${this.props.client.client_id}`, )
      .then(result => {
        if(result.status === 200) {
          this.toggleArchiveModal();
          this.props.history.push('/clients');
        }
      });
  }

  importPBMModal() {
    return (
        <MDBModal show={this.state.showPBMImport} tabIndex='-1'>
          <MDBModalDialog size={"lg"}>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Import client PBM...</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={this.togglePBMImport}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <ImportPBMView client={this.props.client}/>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
    )
  }

  archiveClientModal() {
    return (
      <MDBModal show={this.state.showArchiveModal} tabIndex='-1'>
        <MDBModalDialog size={"lg"}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Are you sure you want to archive this client?</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' type={"button"} onClick={this.toggleArchiveModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className={'archiveModal'}>
              <MDBBtn outline className={"editButton"} color="danger" type='button'
                      onClick={this.onArchive}>Archive Client</MDBBtn>
              <MDBBtn outline className={"editButton"} color="warning" type='button'
                      onClick={this.toggleArchiveModal}>Cancel</MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    )
  }
  render() {
    return (
        parseInt(this.props.match.params.id) !== this.props.client.client_id ?
            <MDBSpinner color='success'/>
            :
      <div className={"clientInfoContainer"}>
        <form onSubmit={(e) => this.onSubmit(e)} ref={this.formRef}>
          <div>
              <MDBNavbar className={'clientInfoNav'}>
                  {!this.state.editMode ?
                    <>
                      <MDBBtn outline className={"archiveButton"} color="danger" type='button'
                              onClick={this.toggleArchiveModal}>Archive Client</MDBBtn>
                      <>
                      {this.state.activeTab === 3 &&
                      <>
                          <MDBBtn outline className={"editButton"} color="success" type='button'
                                  onClick={() => this.togglePBMImport()}>Import PBM</MDBBtn>
                        </>}
                        {(this.state.activeTab === 0 || this.state.activeTab === 1 || this.state.activeTab === 2) &&
                      <MDBBtn outline className={"editButton"} color="warning" type='button'
                              onClick={() => this.setState({editMode: true})}>Edit</MDBBtn>}
                      </>
                    </>
                    :
                            <>
                    <MDBBtn outline className={"editButton"} color="danger" type='button'
                    onClick={() => this.onCancel()}>Cancel</MDBBtn>
                    <MDBBtn outline className={"saveButton"} color="success" type='submit'>Save</MDBBtn>
                    </>
                  }
              </MDBNavbar>
            </div>
            <div className={"tabContainer"}>
                <MDBTabs>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(0)} active={this.state.activeTab === 0}>
                      Client Info
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(1)} active={this.state.activeTab === 1}>
                      Client Contact
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(2)} active={this.state.activeTab === 2}>
                      Benefits Info
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(3)} active={this.state.activeTab === 3}>
                      PBM Prices
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(4)} active={this.state.activeTab === 4}>
                      Rbs Pricing
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink onClick={() => this.handleBasicClick(5)} active={this.state.activeTab === 5}>
                      Client Audit
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
                <MDBTabsContent>
                  <MDBTabsPane show={this.state.activeTab === 0}>{this.clientInfo}</MDBTabsPane>
                  <MDBTabsPane show={this.state.activeTab === 1}>{this.clientContactInfo}</MDBTabsPane>
                  <MDBTabsPane show={this.state.activeTab === 2}>{this.clientBenefitInfo}</MDBTabsPane>
                  <MDBTabsPane show={this.state.activeTab === 3}><PBMPricing pbmPricing={this.props.pbm}/></MDBTabsPane>
                  <MDBTabsPane show={this.state.activeTab === 4}><RBSPrices pbmPricing={this.props.pbm} rbsPricing={this.props.rbsPricing} clientId={this.props.match.params.id}/></MDBTabsPane>
                  <MDBTabsPane show={this.state.activeTab === 5}><AuditList auditList={this.props.clientAudit} /></MDBTabsPane>

                </MDBTabsContent>
          </div>
        </form>
        {this.archiveClientModal()}
        {this.importPBMModal()}
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const boundActions = bindActionCreators({ GetClientAction, GetClientAuditAction, GetPBMAction, GetRBSPricingAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
    clientAudit: state.clientAudit,
    pbm: state.pbm,
    rbsPricing: state.rbsPricing
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(withMsal(ClientView));
