import React, {Component} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {connect} from "react-redux";
import Login from "./views/Login/Login";
import Home from "./views/Home/Home";
import Menu from "./components/Menu";
import Clients from "./views/Clients/Clients";
import ClientView from "./views/Clients/ClientView";
import VendorView from "./views/Vendors/VendorView";
import Vendors from "./views/Vendors/Vendors";
import Drugs from "./views/Drugs/Drugs";
import PBM from "./views/PBM/PBM";

class App extends Component {

  async componentDidMount() {

  }

  render() {
    return (
        <BrowserRouter>
          <Route exact path={'/'}>
            <Login />
          </Route>
            <Route path={'/home'}>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Menu>
                  <Home />
                </Menu>
              </MsalAuthenticationTemplate>
            </Route>
          <Route exact path={'/clients'}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Menu>
                <Clients />
              </Menu>
            </MsalAuthenticationTemplate>
          </Route>
          <Route exact path={'/clients/:id'}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Menu>
                <ClientView />
              </Menu>
            </MsalAuthenticationTemplate>
          </Route>
          <Route exact path={'/vendors'}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Menu>
                <Vendors />
              </Menu>
            </MsalAuthenticationTemplate>
          </Route>
          <Route exact path={'/vendors/:id'}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Menu>
                <VendorView />
              </Menu>
            </MsalAuthenticationTemplate>
          </Route>
          <Route exact path={'/drugs'}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <Menu>
                <Drugs />
              </Menu>
            </MsalAuthenticationTemplate>
          </Route>
            <Route exact path={'/pbm'}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <Menu>
                        <PBM />
                    </Menu>
                </MsalAuthenticationTemplate>
            </Route>
        </BrowserRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
