import types from '../types';

export const initialState = [];

export default function pbmReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PBM:
            const pbm = action.payload;
            if(action.payload !== null) {
                return pbm;
            } else return state;
        default:
            return state;
    }
};
