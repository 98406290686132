import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";
import axiosInstance from '../../util/axios';
import {
    MDBAlert,
    MDBBtn,
    MDBDatatable, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle,
} from "mdb-react-ui-kit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faUnlink} from "@fortawesome/free-solid-svg-icons";
import MatchRBSPriceView from "./MatchRBSPriceView";
import baseUrl from "../../redux/baseUrl";
import axios from "axios";
import {bindActionCreators, compose} from "redux";
import GetRBSPricingAction from "../../redux/actions/GetRBSPricingAction";
import {connect} from "react-redux";


class PBMPricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            comparePrice: null,
            showUnmatchAlert: false
        }

        this.findMatch = this.findMatch.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onSelectDrug = this.onSelectDrug.bind(this);
        this.removeMatch = this.removeMatch.bind(this);
    }

    get tableData() {
        let columns = [
            {
                label: 'Drug Name',
                field: 'drug_name',
                sort: true,
                width: 300
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 350
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 150
            },
            {
                label: 'Price',
                field: 'price',
                sort: true,
                width: 150
            },
            {
                label: 'NDC',
                field: 'ndc',
                sort: true,
                width: 150
            },
            {
                label: '',
                field: 'match',
                sort: true,
                width: 150
            }
        ];
        let rows = this.props.pbmPricing.map((price) => {
            let rbsMatch = this.props.rbsPricing.find(rbsPrice => price.pbm_price_id === rbsPrice.pbm_price_id);
            return {
                drug_name: price.drug_name || "",
                strength: price.strength || "",
                quantity: price.quantity || "",
                price: price.price || "",
                ndc: price.package_ndc || "",
                match: <>
                    {rbsMatch === undefined ?
                    <MDBBtn title={"Edit Price"} outline size={"sm"} color={"success"} role={'button'} key={`${price.pbm_price_id} edit`} className={"projectLink"}
                               onClick={() => this.findMatch(price)}
                               style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faLink}/></MDBBtn>
                        :
            <MDBBtn title={"Edit Price"} outline size={"sm"} color={"danger"} role={'button'} key={`${price.pbm_price_id} edit`} className={"projectLink"}
                    onClick={() => this.removeMatch(rbsMatch)}
                    style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faUnlink}/></MDBBtn>}</>
            }
        });
        return {columns, rows};
    }

    findMatch(price) {
        this.toggleModal();
        this.setState({comparePrice: price})
    }

    async removeMatch(rbsMatch) {
        let axios = await axiosInstance();
        await axios.post(`${baseUrl}/RBSPrices/${rbsMatch.rbs_price_id}/unmatch`)
            .then(result => {
                if(result.data) {
                    this.props.GetRBSPricingAction(this.props.client.client_id);
                } else {
                    this.setState({showUnmatchAlert: true});
                }
            })
    }

    toggleModal() {
        this.setState({modalOpen: !this.state.modalOpen});
    }

    async onSelectDrug(price) {
        let axios = await axiosInstance();
        await axios.post(`${baseUrl}/RBSPrices/${price.rbs_price_id}/match?pbm_price_id=${this.state.comparePrice.pbm_price_id}`, null, )
            .then(result => {
                console.log(result);
                this.setState({comparePrice: null, modalOpen: false});
                this.props.GetRBSPricingAction(this.props.client.client_id);
            })
    }

    matchModal() {
        return (
            <MDBModal className={"fdaModal modal-static"} staticBackdrop={true} show={this.state.modalOpen} tabIndex='-1'>
                <MDBModalDialog size={"xl"}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Match RBS Price</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' role={'button'} onClick={this.toggleModal}/>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <>
                                <MatchRBSPriceView onSelectDrug={this.onSelectDrug} clientId={this.props.client.client_id} pbmPricing={this.props.pbmPricing} />
                            </>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    }

    render() {
        return (
            <>
                <MDBAlert show={this.state.showUnmatchAlert} className='w-100' color='danger'>
                    There was an issue unlinking this PBM price from your RBS price.
                </MDBAlert>
            <MDBDatatable
                striped
                bordered
                advancedData
                search
                fixedHeader
                scrollx
                data={this.tableData}
            />
                {this.matchModal()}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const boundActions = bindActionCreators({ GetRBSPricingAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        rbsPricing: state.rbsPricing,
        client: state.client
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(PBMPricing));
