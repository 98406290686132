import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import moment from 'moment';
import {
    MDBBtn,
    MDBDatatable, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle,
} from "mdb-react-ui-kit";
import './style.scss';

import GetAllPricingAction from "../../redux/actions/GetAllPricingAction";
import AddVendorView from "../Vendors/AddVendorView";
import AddDrugView from "./AddDrugView";


class Drugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            loading: false
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    async componentDidMount() {
        await this.setState({loading: true});
        await this.props.GetAllPricingAction();
        await this.setState({loading: false});
    }

    get tableData() {
        let columns = [
            {
                label: 'Drug Name',
                field: 'name',
                sort: true,
                width: 350
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 225
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 100
            },
            {
                label: 'Price',
                field: 'price',
                sort: true,
                width: 150
            },
            {
                label: 'Unit',
                field: 'unit',
                sort: true,
                width: 100
            },
            {
                label: 'NDC',
                field: 'package_ndc',
                sort: true,
                width: 175
            },
            {
                label: 'Vendor',
                field: 'vendor_name',
                sort: true,
                width: 200
            },
            {
                label: 'Last Update',
                field: 'last_update',
                sort: true,
                width: 100
            },
        ];
        let rows = this.props.pricing.map((price, index) => {
            return {
                name: price.name || "",
                unit: price.unit || "",
                quantity: price.quantity || "",
                price: price.price || "",
                vendor_name: price.vendor_name || "",
                strength: price.strength || "",
                package_ndc: price.package_ndc || "",
                fda_match: price.fda_match.toString() || "",
                last_update: moment(price.last_updated).format('MM/DD/YYYY'),
            }
        });
        return {columns, rows};
    }

    toggleModal() {
        this.setState({modalOpen: !this.state.modalOpen});
    }

    fdaModal() {
        return (
            <MDBModal show={this.state.modalOpen} tabIndex='-1'>
                <MDBModalDialog size={"xl"}>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Search for an NDC from the FDA</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={this.toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <AddDrugView />
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    }

    render() {
        return (
            <div className={"drugListContainer"}>
                {this.state.loading ?
                    <>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </>
                    :
                    <>
                    <div className={"fdaSearchContainer"}>
                        <MDBBtn outline color="primary" type='button'
                                onClick={this.toggleModal}>FDA Search</MDBBtn>
                    </div>
                        <MDBDatatable
                            striped
                            bordered
                            advancedData
                            search
                            fixedHeader
                            maxWidth={'1330px'}
                            data={this.tableData}
                        />
                    </>
                }
                {this.fdaModal()}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const boundActions = bindActionCreators({ GetAllPricingAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        pricing: state.allPricing
    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(Drugs));
