import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  MDBBtn,
  MDBModal, MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBNavbar,
  MDBDatatable
} from "mdb-react-ui-kit";
import GetVendorsAction from "../../redux/actions/GetVendorsAction";
import './style.scss';
import AddVendorView from "./AddVendorView";


class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      loading: false
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    await this.setState({loading: true});
    await this.props.GetVendorsAction();
    await this.setState({loading: false});
  }

  get tableData() {
    let columns = [
      {
        label: 'Vendor Name',
        field: 'vendor_name',
        sort: true,
        width: 200
      },
      {
        label: 'Vendor Phone',
        field: 'vendor_phone',
        sort: true,
        width: 150
      },
      {
        label: 'Vendor Email',
        field: 'vendor_email',
        sort: true,
        width: 350
      },
      {
        label: 'Contact First Name',
        field: 'vendor_contact_first_name',
        sort: true,
        width: 150
      },
      {
        label: 'Contact Last Name',
        field: 'vendor_contact_last_name',
        sort: true,
        width: 150
      },
      {
        label: 'Contact Phone',
        field: 'vendor_contact_phone',
        sort: true,
        width: 150
      },
      {
        label: '',
        field: 'button',
        sort: false,
        width: 150
      }
    ];
    let rows = this.props.vendors.map(vendor => {
      return {
        vendor_name: vendor.vendor_name || "",
        vendor_phone: vendor.vendor_phone || "",
        vendor_email: vendor.vendor_email || "",
        vendor_contact_first_name: vendor.vendor_contact_first_name || "",
        vendor_contact_last_name: vendor.vendor_contact_last_name || "",
        vendor_contact_phone: vendor.vendor_contact_phone || "",
        button: <MDBBtn outline color={"warning"} key={vendor.vendor_id} className={"projectLink"}
                        to={`/clients/${vendor.vendor_id}`}
                        onClick={() => this.props.history.push(`/vendors/${vendor.vendor_id}`)}
                        style={{textDecoration: "underline"}}>View</MDBBtn>
      }
    });
    return {columns, rows};
  }

  toggleModal = () => {
    this.setState({modalOpen: !this.state.modalOpen});
  }

  modalSuccess = async () => {
    this.setState({modalOpen: !this.state.modalOpen});
    await this.props.GetVendorsAction();
  }

  addVendorModal() {
    return (
        <MDBModal show={this.state.modalOpen} tabIndex='-1'>
          <MDBModalDialog size={"lg"}>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Add a Vendor</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={this.toggleModal}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <AddVendorView onSuccess={this.modalSuccess}/>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
    )
  }

  render() {
    return (
        <div>
          {this.state.loading ?
              <>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </>
              :
              <>
                <div>
                  <MDBNavbar className={'vendorInfoNav'}>
                    <form className='editVendorForm'>
                      <MDBBtn outline className={"saveButton"} color="primary" type='button'
                              onClick={this.toggleModal}>+ New Vendor</MDBBtn>
                    </form>
                  </MDBNavbar>
                </div>
                <MDBDatatable
                    striped
                    bordered
                    advancedData
                    search
                    fixedHeader
                    data={this.tableData}
                />
              </>
          }
          {this.addVendorModal()}
        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators({ GetVendorsAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    vendors: state.vendors
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(withMsal(Vendors));
