import types from '../types';

export const initialState = [];

export default function allPricingReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_PRICING:
            const pricing = action.payload;
            if(action.payload !== null) {
                return pricing;
            } else return state;
        default:
            return state;
    }
};
