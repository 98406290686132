import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import {
  MDBAlert,
  MDBBtn,
  MDBInputGroup,
  MDBInputGroupElement,
  MDBInputGroupText, MDBNavbar,
  MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane
} from "mdb-react-ui-kit";
import axiosInstance from "../../util/axios";
import GetVendorAction from "../../redux/actions/GetVendorAction";
import axios from "../../util/axios";


class AddVendorView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      effectiveDate: "",
      showAlert: false
    }
    this.setDate = this.setDate.bind(this);
    this.formRef = React.createRef();
  }

  handleBasicClick(value) {
    if (value === this.state.activeTab) {
      return;
    }

    this.setState({activeTab: value});
  };

  setDate(date) {
    this.setState({effectiveDate: date});
  }

  get vendorInfo() {
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Name</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_name" type='text' placeholder="Vendor Name" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Phone</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_phone" type='tel' placeholder="Vendor's Phone" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Email</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_email" type='email' placeholder="Vendor's Email" />
        </MDBInputGroup>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>Address 1</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_address_1" type='text' placeholder="Address 1" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Address 2</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_address_2" type='text' placeholder="Address 2" />
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>City</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_city" type='text' placeholder="City" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>State</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_state"  type='text' placeholder="State" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Zip</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_zip" type='text' placeholder="Zip" />
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get vendorContactInfo() {
    return (
      <>
        <div className={"sectionedContainer"}>
          <div className={"sectionOne"}>
            <MDBInputGroup>
              <MDBInputGroupText>First Name</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_contact_first_name" type='text' placeholder="Contact First Name" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Last Name</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_contact_last_name" type='text' placeholder="Contact Last Name" />
            </MDBInputGroup>
          </div>
          <div className={"sectionTwo"}>
            <MDBInputGroup>
              <MDBInputGroupText>Email</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_contact_email" type='email' placeholder="Contact Email" />
            </MDBInputGroup>
            <MDBInputGroup>
              <MDBInputGroupText>Phone</MDBInputGroupText>
              <MDBInputGroupElement name="vendor_contact_phone" type='text' placeholder="Contact Phone" />
            </MDBInputGroup>
          </div>
        </div>
      </>
    )
  }

  get vendorBankInfo() {
    return (
      <>
        <MDBInputGroup>
          <MDBInputGroupText>Vendor Bank Name</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_bank_name" type='text' placeholder="Bank Name" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Routing Number</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_bank_routing_number" type='text' placeholder="Routing Number" />
        </MDBInputGroup>
        <MDBInputGroup>
          <MDBInputGroupText>Account Number</MDBInputGroupText>
          <MDBInputGroupElement name="vendor_bank_account_number" type='text' placeholder="Account Number" />
        </MDBInputGroup>
      </>
    )
  }

  async onSubmit(e) {
    e.preventDefault();

    let updatedVendor = {
      "vendor_id": null,
      "vendor_name": this.formRef.current.vendor_name.value || null,
      "vendor_address_1": this.formRef.current.vendor_address_1.value || null,
      "vendor_address_2": this.formRef.current.vendor_address_2.value || null,
      "vendor_city": this.formRef.current.vendor_city.value || null,
      "vendor_state": this.formRef.current.vendor_state.value || null,
      "vendor_zip": this.formRef.current.vendor_zip.value || null,
      "vendor_phone": this.formRef.current.vendor_phone.value || null,
      "vendor_email": this.formRef.current.vendor_email.value || null,
      "vendor_contact_first_name": this.formRef.current.vendor_contact_first_name.value || null,
      "vendor_contact_last_name": this.formRef.current.vendor_contact_last_name.value || null,
      "vendor_contact_email": this.formRef.current.vendor_contact_email.value || null,
      "vendor_contact_phone": this.formRef.current.vendor_contact_phone.value || null,
      "vendor_bank_name": this.formRef.current.vendor_bank_name.value || null,
      "vendor_bank_routing_number": this.formRef.current.vendor_bank_routing_number.value || null,
      "vendor_bank_account_number": this.formRef.current.vendor_bank_account_number.value || null,
      "active": true,
    }

    let axios = await axiosInstance();
    await axios.put('/vendors', updatedVendor, )
      .then(result => {
        if(result.status === 200) {
          this.props.onSuccess();
          this.formRef.current.reset();
        } else {
          this.setState({showAlert: true})
        }
      })
      .catch(error => {
        this.setState({showAlert: true})
      });
  }

  render() {
    return (
      <div className={"addVendorInfoContainer"}>
        <MDBAlert show={this.state.showAlert} className='w-100' color='danger'>
          There was an error creating this vendor.
        </MDBAlert>
        <form onSubmit={(e) => this.onSubmit(e)} ref={this.formRef}>
          <div>
            <MDBNavbar className={'vendorInfoNav'}>
              <MDBBtn outline className={"saveButton"} color="success" type='submit'>Save</MDBBtn>
            </MDBNavbar>
          </div>
          <div className={"tabContainer"}>
            <MDBTabs>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => this.handleBasicClick(0)} active={this.state.activeTab === 0}>
                  Vendor Info
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => this.handleBasicClick(1)} active={this.state.activeTab === 1}>
                  Vendor Contact
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => this.handleBasicClick(2)} active={this.state.activeTab === 2}>
                  Bank Info
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent>
              <MDBTabsPane show={this.state.activeTab === 0}>{this.vendorInfo}</MDBTabsPane>
              <MDBTabsPane show={this.state.activeTab === 1}>{this.vendorContactInfo}</MDBTabsPane>
              <MDBTabsPane show={this.state.activeTab === 2}>{this.vendorBankInfo}</MDBTabsPane>
            </MDBTabsContent>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const boundActions = bindActionCreators({ GetVendorAction }, dispatch);
  return {
    ...boundActions
  }
}

export default compose(
  withRouter,
  connect(mapDispatchToProps))(withMsal(AddVendorView));
