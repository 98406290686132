import React, {Component} from 'react';
import './style.scss';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";
import {MDBBtn} from "mdb-react-ui-kit";
import {connect} from "react-redux";
import {compose} from "redux";


class Login extends Component {

  async componentDidMount() {

  }

  render() {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    const msalInstance = this.props.msalContext.instance;
    if (isAuthenticated) {
      return <MDBBtn onClick={() => msalInstance.logout()}>Logout</MDBBtn>
    } else {
      return (
          <div className={"loginContainer"}>
              <div>
                <h1>Benefits Gateway</h1>
              </div>
            <MDBBtn className={"loginButton"} onClick={() => msalInstance.loginPopup().then(() => {
                this.props.history.push('/home');
            })}>Login</MDBBtn>
          </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(Login));
