import types from '../types';
import baseUrl from '../baseUrl';

const GetClientAudit = (vendorId) => ({
    type: types.GET_CLIENT_AUDIT,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/clients/audit/${vendorId}`,
    },
    options: {
        method: 'GET'
    }
})

export default GetClientAudit;
