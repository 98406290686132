import types from '../types';
import baseUrl from '../baseUrl';

const GetPBM = (clientId) => ({
    type: types.GET_PBM,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/PBM/${clientId}`,
    },
    options: {
        method: 'GET'
    }
})

export default GetPBM;
