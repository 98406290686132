import types from '../types';

export const initialState = [];

export default function vendorsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_VENDORS:
      const vendors = action.payload;
      if(action.payload !== null) {
        return vendors;
      } else return state;
    default:
      return state;
  }
};
