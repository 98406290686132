import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {withRouter} from "react-router";

import {
    MDBBtn,
    MDBDatatable, MDBInput,
} from "mdb-react-ui-kit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faList, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axiosInstance from "../../util/axios";
import baseUrl from "../../redux/baseUrl";
import {bindActionCreators, compose} from "redux";
import GetVendorAction from "../../redux/actions/GetVendorAction";
import GetVendorPricingAction from "../../redux/actions/GetVendorPricingAction";
import GetPricingAuditAction from "../../redux/actions/GetPricingAuditAction";
import GetVendorAuditAction from "../../redux/actions/GetVendorAuditAction";
import {connect} from "react-redux";

class VendorPricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editPriceId: 0,
            user: this.props.msalContext.accounts[0].name
        }

        this.editPrice = this.editPrice.bind(this);
    }

    get tableData() {
        let columns = [
            {
                label: 'Drug Name',
                field: 'name',
                sort: true,
                width: 350
            },
            {
                label: 'Strength',
                field: 'strength',
                sort: true,
                width: 150
            },
            {
                label: 'Quantity',
                field: 'quantity',
                sort: true,
                width: 150
            },
            {
                label: 'Unit',
                field: 'unit',
                sort: true,
                width: 150
            },
            {
                label: 'Price',
                field: 'price',
                sort: true,
                width: 150
            },
            {
                label: 'NDC',
                field: 'ndc',
                sort: true,
                width: 150
            },
            {
                label: 'Actions',
                field: 'actions',
                sort: true,
                width: 100
            }
        ];
        let rows = this.props.vendorPricing.map((price) => {
            return {
                name: price.name || "",
                strength: price.strength || "",
                quantity: this.state.editPriceId === price.vendor_price_id ? <MDBInput key={`${price.vendor_price_id}-quantity`} name={`${price.vendor_price_id}-quantity`} id={`${price.vendor_price_id}-quantity`} type='text' defaultValue={price.quantity}/> : price.quantity,
                price: this.state.editPriceId === price.vendor_price_id ? <MDBInput key={`${price.vendor_price_id}-price`} name={`${price.vendor_price_id}-price`} id={`${price.vendor_price_id}-price`} type='text' defaultValue={price.price}/> : price.price,
                unit: price.unit || "",
                ndc: price.package_ndc || "",
                actions: <>
                    {this.state.editPriceId === price.vendor_price_id ? <MDBBtn title={"Save Price"} outline size={"sm"} color={"success"} role={'button'} key={`${price.vendor_price_id} edit`} className={"projectLink"}
                                                                                onClick={() => this.savePrice(price)}
                                                                                style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faSave}/></MDBBtn> :
                        <MDBBtn title={"Edit Price"} outline size={"sm"} color={"warning"} role={'button'} key={`${price.vendor_price_id} edit`} className={"projectLink"}
                                onClick={() => this.editPrice(price)}
                                style={{textDecoration: "underline"}}><FontAwesomeIcon icon={faEdit}/></MDBBtn>}
                </>
            }
        });
        return {columns, rows};
    }

    editPrice(price) {
        this.setState({editPriceId: price.vendor_price_id});
    }

    async savePrice(price) {
        let newQuantity = document.getElementById(`${price.vendor_price_id}-quantity`).value;
        let newPrice = document.getElementById(`${price.vendor_price_id}-price`).value;

        price.quantity= newQuantity;
        price.price = newPrice;

        let axios = await axiosInstance();
        axios.post(`${baseUrl}/vendorPricing/update?changedBy=${this.state.user}`, price)
            .then(async result => {
                await this.props.GetVendorPricingAction(price.vendor_id);
                this.setState({editPriceId: 0});
            })
    }

    render() {
        return (
                <MDBDatatable
                    striped
                    bordered
                    advancedData
                    search
                    fixedHeader
                    data={this.tableData}
                />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const boundActions = bindActionCreators({ GetVendorPricingAction }, dispatch);
    return {
        ...boundActions
    }
}

const mapStateToProps = (state) => {
    return {
        vendorPricing: state.vendorPricing
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))(withMsal(VendorPricing));
