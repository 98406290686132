import types from '../types';
import {PublicClientApplication} from "@azure/msal-browser";

const apiMiddleware = store => next => action => {
  if (!action.meta || action.meta.type !== 'apijson') {
    return next(action)
  }
  // This is an api request
  const msalConfig = {
    auth: {
      clientId: "a483428a-3497-4176-bcaa-ae0eb15eb09e",
      authority: "https://login.microsoftonline.com/ed752a3a-3d5f-4571-a6bb-fc7a2de4b35e"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  const msalContext = new PublicClientApplication(msalConfig);
  const accounts = msalContext.getAllAccounts();
  if(accounts.length > 0) {
    msalContext.acquireTokenSilent({
      scopes: ["a483428a-3497-4176-bcaa-ae0eb15eb09e/.default"],
      account: accounts[0]
    })
        .then(response => {
          let token = response.accessToken;
          let fetchOptions = {
            method: action.options.method,
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
          if(action.meta.params !== undefined) {
            fetchOptions.body = JSON.stringify(action.meta.params);
          }
          // Make the request
          fetch(action.meta.url, fetchOptions)
              .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                  return resp
                } else {
                  if (typeof action.meta.onFailure === 'function') {
                    action.meta.onFailure(resp)
                    throw Error("Error on endpoint")
                  }
                }
              })
              .then(resp => resp.json())
              .then(json => {
                if (typeof action.meta.onSuccess === 'function') {
                  action.meta.onSuccess(json)
                }
                return json  // For the next promise in the chain
              })
              .then(json => {
                // respond back to the user
                // by dispatching the original action without
                // the meta object
                let newAction = Object.assign({}, action, {
                  payload: json
                });
                delete newAction.meta
                store.dispatch(newAction)
                store.dispatch(getTimeoutObject(false))
              })
              .catch((error) => {
                //caught an error here
                if (error.message === "Timeout") {
                  console.log('got a timeout!!!')
                  store.dispatch(getTimeoutObject(true))
                }
              })
        });
  }
  // Find the request URL and compose request options from meta

}

export function getTimeoutObject(isError) {
  return {
    type: types.TIMEOUT_ERROR,
    payload: isError
  }
}

export default apiMiddleware
