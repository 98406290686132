import types from '../types';
import baseUrl from '../baseUrl';

const GetAllPBM = () => ({
    type: types.GET_ALL_PBM,
    payload: {},
    meta: {
        type: 'apijson',
        url: `${baseUrl}/PBM`,
    },
    options: {
        method: 'GET'
    }
})

export default GetAllPBM;
