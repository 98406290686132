import React from 'react';
import {MDBDatatable} from "mdb-react-ui-kit";
import moment from 'moment';

const AuditList = ({auditList}) => {
    const tableData = () => {
        let columns = [
            {
                label: 'Item',
                field: 'entity',
                sort: true,
                width: 250
            },
            {
                label: 'Property Changed',
                field: 'item_changed',
                sort: true,
                width: 150
            },
            {
                label: 'From',
                field: 'old_value',
                sort: true,
                width: 150
            },
            {
                label: 'To',
                field: 'new_value',
                sort: true,
                width: 150
            },
            {
                label: 'By',
                field: 'changed_by',
                sort: true,
                width: 150
            },
            {
                label: 'Date',
                field: 'date',
                sort: true,
                width: 150
            },
        ];
        let rows = auditList.map((audit) => {
            return {
                item_changed: audit.item_changed || "",
                old_value: audit.old_value || "",
                new_value: audit.new_value || "",
                entity: audit.entity || "",
                date: moment(audit.datetime).format('MM/d/yy hh:mm a').toString() || "",
                changed_by: audit.changed_by || ""
            }
        });
        return {columns, rows};
    }

    return (
        <MDBDatatable
            striped
            bordered
            advancedData
            search
            fixedHeader
            data={tableData()}
        />
    )

}

export default AuditList;
