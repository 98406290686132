import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import './style.scss';

import {
    MDBAlert,
    MDBBtn,
    MDBInputGroup,
    MDBInputGroupElement,
} from "mdb-react-ui-kit";
import GetVendorAction from "../../redux/actions/GetVendorAction";
import FDAView from "./FDAView";
import axios from "axios";

class AddDrugView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            effectiveDate: "",
            showSearchAlert: false,
            showApiAlert: false,
            showAddError: false,
            fdaData: null,
            error: null
        }
        this.formRef = React.createRef();
        this.onSuccess = this.onSuccess.bind(this);
    }

    onSearch = async (e) => {
        e.preventDefault()
        const formValue = this.formRef.current.search.value;
        this.setState({fdaData: null});
        await axios.get(`https://api.fda.gov/drug/ndc.json?api_key=xxKUI6jmXw8UaQrp92PYtT3UVYRD9Erh8bjr5Zne&search=${formValue}&limit=100`)
            .then(async result => {
                if (result.status === 200) {
                    await this.setState({fdaData: result.data, showSearchAlert: false, showApiAlert: false});
                }
            })
            .catch(error => {
                if (error.request.status === 404) {
                    this.setState({showSearchAlert: true});
                } else {
                    this.setState({showApiAlert: true});
                }
            });
    }

    onSuccess() {
        this.props.onSuccess();
    }

    render() {
        return (
            <>
                <MDBAlert show={this.state.showSearchAlert} className='w-100' color='warning'>
                    No drugs were found.
                </MDBAlert>
                <MDBAlert show={this.state.showApiAlert} className='w-100' color='danger'>
                    There was an issue searching the FDA's database.
                </MDBAlert>
                <MDBAlert show={this.state.showAddError} className='w-100' color='danger'>
                    {this.state.error !== null && this.state.error.response.status === 409 ? "This NDC already exists in your database." : "There was an issue adding this drug to your database."}
                </MDBAlert>
                <form onSubmit={this.onSearch} ref={this.formRef}>
                    <MDBInputGroup className='mb-3'>
                        <MDBInputGroupElement name={"search"} placeholder="Drug Name" type='text'/>
                        <MDBBtn outline type={"submit"}>Search</MDBBtn>
                    </MDBInputGroup>
                </form>
                <FDAView fdaData={this.state.fdaData} selectedDrug={null}
                         onError={(error) => this.setState({showAddError: true, error: error})}
                         onSuccess={this.onSuccess}/>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const boundActions = bindActionCreators({ GetVendorAction }, dispatch);
    return {
        ...boundActions
    }
}

export default compose(
    withRouter,
    connect(mapDispatchToProps))(withMsal(AddDrugView));
