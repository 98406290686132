import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import {bindActionCreators, compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import './style.scss';
import {
  MDBBtn,
    MDBDatatable
} from "mdb-react-ui-kit";
import moment from 'moment';
import axiosInstance from "../../util/axios";
import GetVendorPricingAction from "../../redux/actions/GetVendorPricingAction";


class FDAView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.msalContext.accounts[0].name,
    }
    this.formRef = React.createRef();
    this.reconcile = this.reconcile.bind(this);
  }

  get tableData() {
    let columns = [
      {
        label: 'NDC',
        field: 'package_ndc',
        sort: true,
      },
      {
        label: 'Brand Name',
        field: 'brand_name',
        sort: true,
      },
      {
        label: 'Generic Name',
        field: 'generic_name',
        sort: true,
      },
      {
        label: 'Active Ingredients',
        field: 'active_ingredients',
        sort: true,
        width: 300
      },
      {
        label: 'Package Description',
        field: 'description',
        sort: true,
        width: 500
      },
      {
        label: '',
        field: 'button',
        sort: false,
      }
    ];
    let rows = []

    if(this.props.fdaData !== null){
      this.props.fdaData.results.map((drug, index) => {
        return drug.packaging.forEach(pkg => {
          rows.push({
            package_ndc: pkg.package_ndc || "",
            brand_name: drug.brand_name || "",
            active_ingredients:drug.active_ingredients && drug.active_ingredients.length > 0 ? <ul>{drug.active_ingredients.map(ingredient => {
              return <li key={ingredient.name}>{ingredient.name} | {ingredient.strength}</li>
            })}</ul> : "",
            generic_name: drug.generic_name || "",
            description: pkg.description || "",
            button: <MDBBtn outline color={"warning"} key={index}
                            onClick={() => this.reconcile(pkg)}
                            style={{textDecoration: "underline"}}>Choose</MDBBtn>
          });
        });
      });
    }
    return {columns, rows};
  }

  reconcile = async (pkg) => {
    let price = {
      vendor_id: this.props.selectedDrug.vendor_id,
      vendor_price_id: this.props.selectedDrug.vendor_price_id,
      package_ndc: pkg.package_ndc,
      price: this.props.selectedDrug.price,
      strength: this.props.selectedDrug.strength,
      quantity: this.props.selectedDrug.quantity,
      unit: this.props.selectedDrug.unit,
      name: this.props.selectedDrug.name,
      last_updated: moment(),
      fda_match: true
    }

    let axios = await axiosInstance();
    await axios.put(`/vendorPricing/reconcile?changedBy=${this.state.user}`, price, )
        .then(async result => {
          if(result.status === 200) {
            await this.props.GetVendorPricingAction(price.vendor_id);
            this.props.onSuccess();
          }
        })
        .catch(error => console.log(error));
  }

  render() {
    return (
        <div className={'vendorList'}>
          <MDBDatatable
              striped
              bordered
              advancedData
              fixedHeader
              data={this.tableData}
          />
        </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators({ GetVendorPricingAction }, dispatch);
  return {
    ...boundActions
  }
}

const mapStateToProps = (state) => {
  return {
    vendor: state.vendor
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps))(withMsal(FDAView));
