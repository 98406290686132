import types from '../types';

export const initialState = [];

export default function clientAuditReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CLIENT_AUDIT:
            const clientAudit = action.payload;
            if(action.payload !== null) {
                return clientAudit;
            } else return state;
        default:
            return state;
    }
};
