import React, {Component} from 'react';
import {withMsal} from "@azure/msal-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserFriends, faPallet, faPills} from '@fortawesome/free-solid-svg-icons'
import './style.scss'
import {withRouter} from 'react-router';
import {MDBBtn} from "mdb-react-ui-kit";

class Home extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <div className={"rbsContainer"}>
                <MDBBtn onClick={() => this.props.history.push('/clients')}>
                    <FontAwesomeIcon icon={faUserFriends}/> <br/>
                    Clients
                </MDBBtn>
                <MDBBtn onClick={() => this.props.history.push('/vendors')}>
                    <FontAwesomeIcon icon={faPallet}/> <br/>
                    Vendors
                </MDBBtn>
                <MDBBtn onClick={() => this.props.history.push('/drugs')}>
                    <FontAwesomeIcon icon={faPills}/> <br/>
                    Drugs
                </MDBBtn>
            </div>
        )
    }
}
export default withMsal(withRouter(Home));
