import { combineReducers } from 'redux';
import clientsReducer from "./clientsReducer";
import clientIdReducer from "./clientIdReducer";
import clientReducer from "./clientReducer";
import vendorsReducer from "./vendorsReducer";
import vendorReducer from "./vendorReducer";
import vendorIdReducer from "./vendorIdReducer";
import allPricingReducer from "./allPricingReducer";
import vendorPricingReducer from "./vendorPricingReducer";
import vendorPricingAuditReducer from "./pricingAuditReducer";
import vendorAuditReducer from "./vendorAuditReducer";
import clientAuditReducer from "./clientAuditReducer";
import pbmReducer from "./pbmReducer";
import rbsPricingReducer from "./rbsPricingReducer";
import rbsPriceComparisonReducer from "./rbsPriceComparisonReducer";
import allPbmReducer from "./allPbmReducer";

const rootReducer = combineReducers({
  clients: clientsReducer,
  client: clientReducer,
  clientAudit: clientAuditReducer,
  clientId: clientIdReducer,
  vendors: vendorsReducer,
  vendor: vendorReducer,
  vendorAudit: vendorAuditReducer,
  vendorId: vendorIdReducer,
  allPricing: allPricingReducer,
  vendorPricing: vendorPricingReducer,
  vendorPricingAudit: vendorPricingAuditReducer,
  allPbm: allPbmReducer,
  pbm: pbmReducer,
  rbsPricing: rbsPricingReducer,
  rbsPriceComparison: rbsPriceComparisonReducer,
})

export default rootReducer
